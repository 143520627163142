import { Environment } from './environment.type';

export const environment: Environment = {
    LOGIN_URL: 'https://login.bannerflow.com',
    CLIENT_ID: '6VMNTQOLjdEDy1Bmo8M7FyOSuvsKC9eG',
    API_URL: 'https://am.bannerflow.com',
    BAU_URL: 'https://api.bannerflow.com/bau',
    AC_URL: 'https://api.bannerflow.com/account-cleanup',
    HOME_URL: 'https://home.bannerflow.com',
    NAME: 'production',

    NEW_RELIC_ACCOUNT_ID: '4122654',
    NEW_RELIC_APPLICATION_ID: '538568840',
    NEW_RELIC_LICENSE_KEY: 'NRJS-bd28b6acdc31b77b97c',
    NEW_RELIC_TRUST_KEY: '4122654',
    RELEASE_NAME: '',
    VERSION: ''
};
